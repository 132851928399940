import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../../libraries/enums/permissionRights";
import { buildRevision } from "../../builders/revision.builder";
import revisionSlice from "../../../revision/slices/revisionSlice";
import { RevisionType } from "../../../../types";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { REVISION_TYPE_CODE__initial } from "../../../../libraries/enums/revisionTypes";

type ActionProps = {
  revision: RevisionType;
  children?: any;
};

const RevisionActionCreateBasedOnRevisionTemplate = (props: ActionProps) => {
  const { revision, children = null } = props;

  const dispatch = useAppDispatch();
  const hasPermissionRightCreateRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createRevision,
    })
  );

  const handleClick = () => {
    const r = buildRevision();
    r.typeId = REVISION_TYPE_CODE__initial;
    r.revisionSubjectId = revision.revisionSubjectId;
    r.unitId = revision.unitId;
    r.unitStoreNumber = revision.unitStoreNumber;
    r.revisionSubjectUnitRequirementId = revision.id;
    dispatch(revisionSlice.actions.selectedRevisionSet(r));
    dispatch(revisionSlice.actions.selectedRevisionModeSet("create"));
  };

  if (!hasPermissionRightCreateRevision) {
    return <></>;
  }

  if (!!children) {
    return (
      <>
        {React.cloneElement(children, {
          onClick: () => {
            handleClick();
          },
        })}
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        handleClick();
      }}
      color={"primary"}
      size={"small"}
    >
      <AddCircle fontSize={"small"} />
    </Button>
  );
};

export default RevisionActionCreateBasedOnRevisionTemplate;
