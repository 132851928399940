export const autocompleteUnitFilterOptions = (
  options: any,
  { inputValue }: { inputValue: string }
) => {
  const search = inputValue
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  return options.filter((option: any) => {
    const valuesToSearch = [
      option.storeNumber,
      option.title,
      option.subtitle,
      option.addressText,
      option.brand?.code,
      option.brand?.title,
    ];

    return valuesToSearch.some((field) =>
      (field || "")
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .includes(search)
    );
  });
};
