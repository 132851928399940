import { AppDispatch } from "../../../app/store";
import { RevisionType } from "../../../types";
import { REVISION_TYPE_CODE__regular } from "../../../libraries/enums/revisionTypes";
import {
  getRevisionExpirationStatusByRevision,
  REVISION_EXPIRATION_STATUS_after,
} from "../../../libraries/enums/revisionExpirationStatuses";

export const handleDownloadRevision =
  (revisionFile: any) => (dispatch: AppDispatch) => {
    const link = document.createElement("a");
    link.href =
      process.env.REACT_APP_ENDPOINT_FILES +
      "/" +
      revisionFile.path +
      "/originals/" +
      revisionFile.filename;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

export const isRevisionRequiredAndWithExpiredRevisionList = (
  revision: RevisionType
): boolean => {
  const isRequired = revision.revisionSubjectUnitRequired || false; // revize je požadována
  const isRegularAndExpired =
    revision.typeId === REVISION_TYPE_CODE__regular &&
    getRevisionExpirationStatusByRevision(revision).code ===
      REVISION_EXPIRATION_STATUS_after; // revize je periodická a již byla vyexpirovaná

  return isRequired && isRegularAndExpired;
};

export const isRevisionRequiredAndWithNoRevisionList = (
  revision: RevisionType
): boolean => {
  const isRequired = revision.revisionSubjectUnitRequired || false; // revize je požadována
  const hasNoFiles = !revision.files || !revision.files[0].id; // revize nemá žádné soubory (např. požadovaná výchozí revize bez souborů)

  return isRequired && hasNoFiles;
};
