import { useState } from "react";
import { Alert, Box, TextField } from "@mui/material";
import { getAuthTokenViaCredentials, saveAuthToken } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { loadProfile } from "../../../../app/profile.utils";
import { useAppDispatch } from "../../../../app/store";

const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginName, setLoginName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async () => {
    setErrorMessage("");
    setFetching(true);
    getAuthTokenViaCredentials(loginName, password)
      .then(async (response: any) => {
        if (!!response && response.status === 200 && !!response.data) {
          await saveAuthToken(response.data.token);
          await loadProfile(response.data.token, { dispatch, navigate });
        }
      })
      .catch((e: any) => {
        setErrorMessage("Přihlášení se nepodařilo.");
        setFetching(false);
      });
  };

  return (
    <>
      <Box>
        <TextField
          label="Přihlašovací jméno"
          required
          name={"loginName"}
          variant="outlined"
          margin="normal"
          fullWidth
          value={loginName}
          autoFocus
          onChange={(event) => {
            setLoginName(event.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />

        <TextField
          type={"password"}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Heslo"
          name={"password"}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />

        {errorMessage !== "" && (
          <Alert sx={{ mt: 1 }} severity={"error"}>
            {errorMessage}
          </Alert>
        )}

        <LoadingButton
          disabled={fetching}
          loading={fetching}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Přihlásit
        </LoadingButton>
      </Box>
    </>
  );
};

export default LogIn;
