import { useAppSelector } from "../../../../app/store";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import { Box, Chip } from "@mui/material";
import { formatDate } from "../../../common/utils/dateTime.utils";
import TodayIcon from "@mui/icons-material/Today";
import React from "react";
import { getRevisionExpirationStatusByRevision } from "../../../../libraries/enums/revisionExpirationStatuses";
import { INVALID_DUE_REPLACEMENT } from "../../constants/revision.constants";
import { REVISION_TYPE_CODE__regular } from "../../../../libraries/enums/revisionTypes";

const FieldNextRevisionAt = (props: any) => {
  const { revisionId, isDetail = false } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  if (
    !revision ||
    !revision.files ||
    !revision.files[0].id ||
    !revision.nextRevisionAt ||
    revision.statusId === INVALID_DUE_REPLACEMENT
  ) {
    return <></>;
  }

  const expirationStatus = getRevisionExpirationStatusByRevision(revision);

  return (
    <>
      <Box sx={{ p: 2, width: "100%" }}>
        <Box
          sx={{
            flexWrap: "wrap",
            whiteSpace: "nowrap",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Chip
            size="small"
            label={formatDate(revision.nextRevisionAt as string, "d. L. yy")}
            icon={<TodayIcon />}
            sx={{
              mr: 1,
              "& .MuiChip-label": {
                overflow: "auto",
              },
            }}
          />
          {revision.typeId === REVISION_TYPE_CODE__regular && (
            <Box sx={{ mt: 0.5, ml: 1, fontSize: 14 }}>
              <Box {...expirationStatus.field.props}>
                {expirationStatus.field.getText(revision)}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export const fieldNextRevisionAt = {
  code: "fieldNextRevisionAt",
  label: "Příští revize",
  component: FieldNextRevisionAt,
};
