import { blue, indigo, yellow } from "@mui/material/colors";
import { Loop, PriorityHigh, Start } from "@mui/icons-material";

export const REVISION_TYPE_CODE__regular = "regular";
export const REVISION_TYPE_CODE__initial = "initial";
export const REVISION_TYPE_CODE__extraordinary = "extraordinary";

const REVISION_TYPES = {
  [REVISION_TYPE_CODE__initial]: {
    code: REVISION_TYPE_CODE__initial,
    icon: Start,
    color: "#000000",
    bgcolor: yellow[100],
    label: "Výchozí",
  },
  [REVISION_TYPE_CODE__regular]: {
    code: REVISION_TYPE_CODE__regular,
    icon: Loop,
    color: "#000000",
    bgcolor: blue[100],
    label: "Periodická",
  },
  [REVISION_TYPE_CODE__extraordinary]: {
    code: REVISION_TYPE_CODE__extraordinary,
    icon: PriorityHigh,
    color: "#000000",
    bgcolor: indigo[100],
    label: "Mimořádná",
  },
};

export type revisionTypeCodeType = keyof typeof REVISION_TYPES;

export const getRevisionTypeByCode = (code: revisionTypeCodeType) => {
  return REVISION_TYPES[code];
};

export const getRevisionTypes = () => {
  return Object.values(REVISION_TYPES);
};
