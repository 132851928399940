import { BrandType, Nullable } from "../../../../types";
import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { formatAddress } from "../../utils/address.utils";
import { autocompleteUnitFilterOptions } from "../../../unit/utils/autocompleteUnitFilterOptions.utils";

export interface FormFieldOption {
  inputValue?: string;
  title: string;
  id: string;
}

type Payload = Nullable<{ property: string; value: any; option: any }>;

interface FormFieldAutocompleteUnitProps {
  label: string;
  property: string;
  defaultOptionId: Nullable<string>;
  creatable: boolean;
  options: any[];
  dialog?: {
    headerText: string;
    contentText: string;
  };
  onChange: (payload: Payload[]) => any;
}

const FormFieldAutocompleteUnit = (props: FormFieldAutocompleteUnitProps) => {
  const { label, property, onChange, defaultOptionId, options } = props;

  const [value, setValue] = useState<Nullable<FormFieldOption>>(null);

  const changeValue = (value: any) => {
    setValue(value);
    const payload = [];
    payload.push({
      property,
      value,
      option: options.find((o) => o.id === value) || null,
    });
    onChange(payload);
  };

  useEffect(() => {
    if (options.length === 1) {
      changeValue(options[0].id);
    }
  }, [options]);

  useEffect(() => {
    if (!!defaultOptionId) {
      changeValue(defaultOptionId);
    }
  }, [defaultOptionId]);

  return (
    <Autocomplete
      filterOptions={autocompleteUnitFilterOptions}
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeValue(newValue.id);
        } else {
          changeValue(null);
        }
      }}
      value={options.find((u) => u.id === value) || null}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default FormFieldAutocompleteUnit;
